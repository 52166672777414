import { Box, Button, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useStore } from '../../stores';

interface IntroduceProps {
  startNewDivision?: any;
}

const Introduce = (props: IntroduceProps) => {
  const { startNewDivision } = props;
  const [divisionActive, setDivisionActive] = useState(1);
  const [totalQuestion, setTotalQuestion] = useState(20);
  const [totalTime, setTotalTime] = useState(6);
  const { todoStore, examinationStore } = useStore();

  useEffect(() => {
    let examType = examinationStore.examType;
    let division = examinationStore.divisionActive;
    if (division == 0 || (examType == 1 && division >= 3) || (examType == 2 && division >= 2)) {
      setDivisionActive(1);
      examinationStore.setDivisionActive(1);
    } else {
      setDivisionActive(division + 1);
      examinationStore.setDivisionActive(division + 1);
    }

    if (examType == 1) {
      if (examinationStore.divisionActive == 1) {
        setTotalQuestion(100);
        setTotalTime(25);
      } else if (examinationStore.divisionActive == 2 || examinationStore.divisionActive == 3) {
        setTotalQuestion(100);
        setTotalTime(12);
      }
    } else if (examType == 2) {
      if (examinationStore.divisionActive == 1) {
        setTotalQuestion(20);
        setTotalTime(6);
      } else if (examinationStore.divisionActive == 2) {
        setTotalQuestion(120);
        setTotalTime(12);
      }
    }
  }, []);

  const handleNext = () => {
    todoStore.setPageName('questions');
    if (startNewDivision) startNewDivision();
  };

  return (
    <>
      <Box sx={{ display: 'grid', textAlign: 'left', width: '70%', margin: 'auto' }}>
        <Typography component="div">
          <Typography component="p" sx={sxStyle.head}>
            第 {divisionActive} 部 合計{totalQuestion}問
            {examinationStore.examType == 1 &&
              divisionActive == 1 &&
              '（制限時間' + totalTime + '分）'}
            {examinationStore.examType == 2 && divisionActive == 1 && '制限時間' + totalTime + '分'}
            {divisionActive != 1 && '（' + totalTime + '分を目処）'}
          </Typography>{' '}
          <Typography component="p">第{divisionActive}部のテストを行います。</Typography>
          <Typography component="p">
            テスト開始する場合は「テスト開始」を教えてください。
          </Typography>
          <Typography component="div" sx={sxStyle.buttonRegion}>
            <Button
              sx={sxStyle.btnAction}
              onClick={handleNext}
              color="primary"
              variant="outlined"
              type="submit"
            >
              テスト開始
            </Button>
          </Typography>
        </Typography>
      </Box>
    </>
  );
};

const sxStyle = {
  root: { textAlign: 'left', margin: 'auto', width: '80%' },
  head: { marginBottom: '30px' },
  item: { display: 'flex' },
  inputRegion: { display: 'flex', width: '80%' },
  labelText: { textAlign: 'left', margin: 'auto', width: '20%' },
  labelTextSuport: { margin: 'auto', marginLeft: '10px' },
  btnAction: {
    width: '236px',
    height: '70px',
    fontSize: '20px',
    color: '#fff',
    margin: 'auto',
    backgroundColor: '#088a98',
    '&:hover': {
      opacity: '.4',
      background: '#088a98',
      borderColor: '#088a98'
    }
  },
  textInfo: { marginLeft: '15px' },
  buttonRegion: { marginTop: '50px', display: 'flex' }
};

export default Introduce;
