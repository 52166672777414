import { Box, BoxProps, Typography } from '@mui/material';
import InputField from 'components/FormFields/InputField';
import { FormikValues } from 'formik';
import React, { FC } from 'react';
const Item = (props: BoxProps) => {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        bgcolor: 'background.paper',
        p: 1,
        borderRadius: 1,
        textAlign: 'center',
        fontSize: 19,
        fontWeight: '700',
        height: 'auto',
        ...sx
      }}
      {...other}
    />
  );
};

interface PropsType {
  formField: FormikValues;
  errors: any;
  touched: any;
}

const CandidateInfomation: FC<PropsType> = (props) => {
  const {
    formField: { firstName, lastName, furigana1, furigana2, age, department }
  } = props;

  return (
    <>
      <Box sx={{ display: 'grid', width: '60%', margin: 'auto' }}>
        <Item sx={sxStyle.item}>
          <Typography sx={sxStyle.labelText} component="span">
            氏名
          </Typography>
          <Typography sx={sxStyle.inputRegion} component="div">
            <InputField sx={{ marginRight: '5px' }} name={firstName.name} maxLength={6} />
            <InputField name={lastName.name} maxLength={6} />
          </Typography>
        </Item>
        <Item sx={sxStyle.item}>
          <Typography sx={sxStyle.labelText} component="span">
            フリガナ
          </Typography>
          <Typography component="div" sx={sxStyle.inputRegion}>
            <InputField sx={{ marginRight: '5px' }} name={furigana1.name} maxLength={6} />
            <InputField name={furigana2.name} maxLength={6} />
          </Typography>
        </Item>
        <Item sx={sxStyle.item}>
          <Typography sx={sxStyle.labelText} component="span">
            年齢
          </Typography>
          <Typography component="div" sx={sxStyle.inputRegion}>
            <InputField name={age.name} maxLength={2} style={{ width: '45%' }} type="number" />
            <Typography sx={sxStyle.labelTextSuport} component="span">
              歳
            </Typography>
          </Typography>
        </Item>
        <Item sx={sxStyle.item}>
          <Typography sx={sxStyle.labelText} component="span">
            所属部署
          </Typography>
          <Typography component="div" sx={sxStyle.inputRegion}>
            <InputField name={department.name} maxLength={11} />
          </Typography>
        </Item>
      </Box>
    </>
  );
};

const sxStyle = {
  root: { textAlign: 'left', margin: 'auto', width: '80%' },
  item: { display: 'flex' },
  inputRegion: { display: 'flex', width: '80%' },
  labelText: { textAlign: 'left', margin: 'auto', width: '20%' },
  labelTextSuport: { margin: 'auto', marginLeft: '10px' },
  btnNext: {
    width: '50%',
    margin: 'auto'
  },
  textInfo: { marginLeft: '15px' },
  buttonRegion: { marginTop: '50px', display: 'flex' }
};

export default CandidateInfomation;
