import { useField } from 'formik';
import React from 'react';
import { at } from 'lodash';
import { TextField } from '@mui/material';

const InputField = (props: any) => {
  const { isShowError = true, placeholder, maxLength, ...restProps } = props;
  const [field, meta] = useField(props);

  const renderHelperText = () => {
    if (isShowError) {
      const [touched, error] = at(meta, 'touched', 'error');
      if (touched && error) {
        return error;
      }
    }
  };

  return (
    <>
      <TextField
        variant="outlined"
        size="small"
        inputProps={{ maxLength: maxLength }}
        error={meta.touched && meta.error && true}
        helperText={renderHelperText()}
        id="standard-basic"
        {...field}
        {...restProps}
        fullWidth
        placeholder={placeholder}
      />
      {/* {meta.error && meta.touched && <div>{meta.error}</div>} */}
    </>
  );
};

export default InputField;
