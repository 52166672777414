import { action, observable, makeObservable } from 'mobx';

export class Examination {
  @observable totalTime: number = 0;
  @observable questionList: any[] = [];
  @observable examType: number = 2;
  @observable timeHasPassed: number = 0;
  @observable isTimeOut: boolean = false;
  @observable examination: any = null;
  @observable divisionActive: number = 0;
  @observable baseId: number = 0;
  @observable urlId: number = 0;
  @observable isStart: boolean = false;
  @observable questionNoActive: number = 0;
  @observable pageActive: number = 0;
  @observable answerId: number = 0;
  @observable issueType: number = 2;
  @observable responseUrl: string = '';

  constructor() {
    makeObservable(this);
  }

  @action.bound
  setTotalTime = (time: number) => {
    this.totalTime = time;
  };

  @action.bound
  setQuestionList = (questionList: any[]) => {
    this.questionList = questionList;
  };

  @action.bound
  setExamType = (examType: number) => {
    this.examType = examType;
  };

  @action.bound
  setTimeHasPassed = (time: number) => {
    this.timeHasPassed = time;
  };

  @action.bound
  setIsTimeOut = (isTimeOut: boolean) => {
    this.isTimeOut = isTimeOut;
  };

  @action.bound
  setExamination = (examination: any) => {
    this.examination = examination;
  };

  @action.bound
  setDivisionActive = (divisionActive: any) => {
    this.divisionActive = divisionActive;
  };

  @action.bound
  setBaseId = (baseId: number) => {
    this.baseId = baseId;
  };

  @action.bound
  setUrlId = (urlId: number) => {
    this.urlId = urlId;
  };

  @action.bound
  setIsStart = (isStart: boolean) => {
    this.isStart = isStart;
  };

  @action.bound
  setQuestionNoActive = (questionNoActive: number) => {
    this.questionNoActive = questionNoActive;
  };

  @action.bound
  setPageActive = (pageActive: number) => {
    this.pageActive = pageActive;
  };

  @action.bound
  setAnswerId = (answerId: number) => {
    this.answerId = answerId;
  };

  @action.bound
  setIssueType = (issueType: number) => {
    this.issueType = issueType;
  };

  @action.bound
  setResponseUrl = (responseUrl: string) => {
    this.responseUrl = responseUrl;
  };
}
