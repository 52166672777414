export const formModel = {
  formId: 'examinationForm',
  formField: {
    firstName: {
      name: 'firstName',
      requiredErrorMsg: '氏名を入力してください。'
    },
    lastName: {
      name: 'lastName',
      requiredErrorMsg: '氏名を入力してください。'
    },
    furigana1: {
      name: 'furigana1',
      requiredErrorMsg: 'フリガナを入力してください。'
    },
    furigana2: {
      name: 'furigana2',
      requiredErrorMsg: 'フリガナを入力してください。'
    },
    age: {
      name: 'age',
      requiredErrorMsg: '年齢を入力してください。',
      lengthErrorMsg: 'Please choose a valid age'
    },
    department: {
      name: 'department',
      lengthErrorMsg: 'Department must be at least 6 characters'
    }
  }
};
