import { Typography } from '@mui/material';
import * as React from 'react';
// import logo from '../../../logo.svg';
import { useStore } from '../../../stores';

interface HeaderProps {
  examType: number;
}

const Header = (props: HeaderProps) => {
  const { examType } = props;
  const { examinationStore } = useStore();
  const [content, setContent] = React.useState<string>('第１部　合計１０問　制限時間６分');

  React.useEffect(() => {
    if (examinationStore.examType == 1) {
      if (examinationStore.divisionActive == 1) {
        setContent('第１部　合計１００問　制限時間25分');
      } else if (examinationStore.divisionActive == 2) {
        setContent('第2部　合計１００問　12分を目処');
      } else if (examinationStore.divisionActive == 3) {
        setContent('第3部　合計１００問　12分を目処');
      }
    }
    if (examinationStore.examType == 2) {
      if (examinationStore.divisionActive == 1) {
        setContent('第１部　合計２０問　制限時間６分');
      } else if (examinationStore.divisionActive == 2) {
        setContent('第2部　合計１2０問　12分を目処');
      }
    }
  });

  return (
    <Typography component="div" sx={sxStyle.root}>
      <Typography component="div">
        {/*<img style={{ width: '100%', justifyContent: 'center' }} src={logo} alt="" />*/}
      </Typography>
      <Typography sx={sxStyle.content} component="div">
        <Typography sx={sxStyle.contentExamtype} component="div">
          {examType == 1 ? 'DAT-FULL' : 'DAT_M'}
        </Typography>
        <Typography sx={sxStyle.contentDescription} component="div">
          {content}
        </Typography>
      </Typography>
    </Typography>
  );
};

const sxStyle = {
  root: { margin: 'auto 0', width: '100%', display: 'flex' },
  logo: { margin: 'auto 0', width: '30' },
  content: { margin: 'auto 20px', width: '70%', textAlign: 'left', marginLeft: '15px' },
  contentDescription: { marginLeft: '0' },
  contentExamtype: {
    margin: 'auto 20px',
    width: '70%',
    fontSize: '24px',
    color: '#df8e01',
    textAlign: 'left',
    fontWeight: 'bold',
    marginLeft: 0
  }
};

export default Header;
