import { action, observable, makeObservable } from 'mobx';

export class Todo {
  @observable pageName = 'datm';

  constructor() {
    makeObservable(this);
  }

  @action.bound // chỉ có action mới có thể modify observable state
  setPageName = (pageName: string) => {
    this.pageName = pageName;
  };
}
