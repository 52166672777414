import React from 'react';
import { Box, CircularProgress, Modal, Typography } from '@mui/material';

export interface ModalLoadingProps {
  loading?: boolean;
}

const ModalLoading = (props: ModalLoadingProps) => {
  const { loading = true } = props;
  return (
    <Modal
      open={loading}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="BoxItem" sx={[sxStyle.box]}>
        <Typography sx={sxStyle.wrapCircular} component={'div'}>
          <CircularProgress color="secondary" />
        </Typography>
      </Box>
    </Modal>
  );
};

const sxStyle = {
  box: {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'fit-content',
    height: 'fit-content',
    outline: 'none'
  },
  wrapCircular: {
    display: 'flex',
    justifyContent: 'center',
    mb: 2,
    pr: 1
  }
};

export default ModalLoading;
