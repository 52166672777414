import { Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useStore } from 'stores';

const AnswerOptions = (props: any) => {
  const { examinationStore } = useStore();
  const {
    questionNo,
    detailNo,
    division,
    choice1,
    choice2,
    choice3,
    choice4,
    choice5,
    isNullChoice = false,
    currentPage = 0,
    radioChangeFunc = null,
    type = 'single'
  } = props;
  const [valueSelected, setValueSelected] = useState<number>(0);

  const choice1Ref = useRef(null);
  const choice2Ref = useRef(null);
  const choice3Ref = useRef(null);
  const choice4Ref = useRef(null);
  const choice5Ref = useRef(null);

  const isTabletDevice = useMediaQuery({
    // @ts-ignore
    query: '(max-width: 768px)'
  });

  useEffect(() => {
    setValueSelected(0);
    let examinationData = JSON.parse(localStorage.getItem('examinationData') || 'null');
    if (examinationData && examinationData.answers) {
      let answer = examinationData.answers.find(
        (answer) =>
          answer.questionNo ===
            (division === 1 && typeof questionNo === 'string'
              ? parseInt(questionNo.replace('設問', ''))
              : questionNo) &&
          answer.division === division &&
          answer.detailNo === detailNo
      );
      if (answer) {
        setValueSelected(answer.answer_num);
      }
    }
  }, [questionNo, division]);

  const handleRadioChange = (event) => {
    if (event.target.value) {
      setValueSelected(event.target.value);
      let examinationData = JSON.parse(localStorage.getItem('examinationData') || '{}');
      const dataAnswer = {
        questionNo:
          division === 1 && typeof questionNo === 'string'
            ? parseInt(questionNo.replace('設問', ''))
            : questionNo,
        detailNo: detailNo,
        division: division,
        answer_num: event.target.value,
        page: currentPage
      };
      if (examinationData.answers) {
        let answer = examinationData.answers.find(
          (answer) =>
            answer.questionNo ===
              (division === 1 && typeof questionNo === 'string'
                ? parseInt(questionNo.replace('設問', ''))
                : questionNo) &&
            answer.division === division &&
            answer.detailNo == detailNo
        );
        if (answer) {
          answer.answer_num = event.target.value;
        } else {
          examinationData.answers.push(dataAnswer);
        }
      } else {
        examinationData.answers = [dataAnswer];
      }
      localStorage.setItem('examinationData', JSON.stringify(examinationData));
      if (examinationStore.divisionActive == 1) {
        radioChangeFunc();
      }
    }
  };

  // const handlechange = () => {
  //   let a = choice1Ref;
  //   console.log(a);
  // };

  return (
    <>
      <Typography component="div" sx={isTabletDevice ? sxStyle.rootMobile : sxStyle.root}>
        {choice1 && (
          <Typography
            component="div"
            sx={
              isTabletDevice
                ? sxStyle.radioRegionMobile
                : type == 'single'
                ? sxStyle.radioRegionSingle
                : sxStyle.radioRegionMutiple
            }
          >
            <input
              type="radio"
              name={currentPage + '' + division + '' + questionNo + '' + detailNo}
              value={1}
              onChange={handleRadioChange}
              checked={valueSelected == 1}
              ref={choice1Ref}
              style={{ width: '17px', height: '17px' }}
            />
            <Typography
              onClick={
                // @ts-ignore
                () => choice1Ref?.current?.click()
              }
              component="span"
              sx={
                type == 'single'
                  ? sxStyle.descriptionAnswerSingle
                  : sxStyle.descriptionAnswerMutiple
              }
            >
              {type == 'single' && '①'} {!isNullChoice ? choice1 : ''}
            </Typography>
          </Typography>
        )}

        {choice2 && (
          <Typography
            component="div"
            sx={
              isTabletDevice
                ? sxStyle.radioRegionMobile
                : type == 'single'
                ? sxStyle.radioRegionSingle
                : sxStyle.radioRegionMutiple
            }
          >
            <input
              type="radio"
              name={currentPage + '' + division + '' + questionNo + '' + detailNo}
              value={2}
              onChange={handleRadioChange}
              checked={valueSelected == 2}
              ref={choice2Ref}
              style={{ width: '17px', height: '17px' }}
            />
            <Typography
              onClick={
                // @ts-ignore
                () => choice2Ref?.current?.click()
              }
              component="span"
              sx={
                type == 'single'
                  ? sxStyle.descriptionAnswerSingle
                  : sxStyle.descriptionAnswerMutiple
              }
            >
              {type == 'single' && '②'} {!isNullChoice ? choice2 : ''}
            </Typography>
          </Typography>
        )}

        {choice3 && (
          <Typography
            component="div"
            sx={
              isTabletDevice
                ? sxStyle.radioRegionMobile
                : type == 'single'
                ? sxStyle.radioRegionSingle
                : sxStyle.radioRegionMutiple
            }
          >
            <input
              type="radio"
              name={currentPage + '' + division + '' + questionNo + '' + detailNo}
              value={3}
              onChange={handleRadioChange}
              checked={valueSelected == 3}
              ref={choice3Ref}
              style={{ width: '17px', height: '17px' }}
            />
            <Typography
              onClick={
                // @ts-ignore
                () => choice3Ref?.current?.click()
              }
              component="span"
              sx={
                type == 'single'
                  ? sxStyle.descriptionAnswerSingle
                  : sxStyle.descriptionAnswerMutiple
              }
            >
              {type == 'single' && '③'} {!isNullChoice ? choice3 : ''}
            </Typography>
          </Typography>
        )}

        {choice4 && (
          <Typography
            component="div"
            sx={
              isTabletDevice
                ? sxStyle.radioRegionMobile
                : type == 'single'
                ? sxStyle.radioRegionSingle
                : sxStyle.radioRegionMutiple
            }
          >
            <input
              type="radio"
              name={currentPage + '' + division + '' + questionNo + '' + detailNo}
              value={4}
              onChange={handleRadioChange}
              checked={valueSelected == 4}
              ref={choice4Ref}
              style={{ width: '17px', height: '17px' }}
            />
            <Typography
              onClick={
                // @ts-ignore
                () => choice4Ref?.current?.click()
              }
              component="span"
              sx={
                type == 'single'
                  ? sxStyle.descriptionAnswerSingle
                  : sxStyle.descriptionAnswerMutiple
              }
            >
              {type == 'single' && '④'} {!isNullChoice ? choice4 : ''}
            </Typography>
          </Typography>
        )}

        {choice5 && (
          <Typography
            component="div"
            sx={
              isTabletDevice
                ? sxStyle.radioRegionMobile
                : type == 'single'
                ? sxStyle.radioRegionSingle
                : sxStyle.radioRegionMutiple
            }
          >
            <input
              type="radio"
              name={currentPage + '' + division + '' + questionNo + '' + detailNo}
              value={5}
              onChange={handleRadioChange}
              checked={valueSelected == 5}
              ref={choice5Ref}
              style={{ width: '17px', height: '17px' }}
            />
            <Typography
              onClick={
                // @ts-ignore
                () => choice5Ref?.current?.click()
              }
              component="span"
              sx={
                type == 'single'
                  ? sxStyle.descriptionAnswerSingle
                  : sxStyle.descriptionAnswerMutiple
              }
            >
              {type == 'single' && '⑤'} {!isNullChoice ? choice5 : ''}
            </Typography>
          </Typography>
        )}
      </Typography>
    </>
  );
};

const sxStyle = {
  root: { display: 'flex', width: '100%', marginTop: '10px', justifyContent: 'center' },
  rootMobile: { display: 'column', width: '100%', marginTop: '10px' },
  radioRegionSingle: { display: 'flex', marginRight: '20px' },
  radioRegionMutiple: { display: 'flex', marginRight: '20px' },
  radioRegionMobile: { display: 'flex', width: '100%', alignItems: 'center' },
  descriptionAnswerSingle: { cursor: 'pointer' },
  descriptionAnswerMutiple: { cursor: 'pointer', fontSize: '12px' },
  btnNext: {
    position: 'absolute',
    marginBottom: '0 !important',
    bottom: '20px',
    width: '100%',
    margin: '50%',
    transform: 'translate(-50%, 0)'
  }
};

export default AnswerOptions;
