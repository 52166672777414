import { Button, Divider, Typography } from '@mui/material';
import { TimeOut } from 'components/Common';
import Header from 'components/Layout/Header/Header';
import TimeProcessContainer from 'components/TimeProcess/TimeProcessContainer';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import ExaminationService from 'services/examinationService';
import { useStore } from 'stores';
import AnswerOptionForList from '../component/AnswerOptionForList';
import CompleteExamination from '../../../components/Layout/CompleteExamination/CompleteExamination';
import ModalLoading from '../../../components/Loading/ModalLoading';
import { pushDataForThirdParty } from 'utils/pushDataForThirdParty';

const MutipleQuestions = observer((props: any) => {
  const { divisionProp } = props;
  const { examinationStore, todoStore } = useStore();
  const [questionList, setQuestionList] = useState<any[]>([]);
  const [pageActive, setPageActive] = useState(1);
  const [totalPage, setTotalPage] = useState(10);
  const [division, setDivision] = useState(divisionProp);
  const [isEndPage, setIsEndPage] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const startTime = () => {
    // let timeTest = 999;
    if (examinationStore.examType == 1) {
      if (examinationStore.divisionActive == 1) {
        examinationStore.setTotalTime(1500);
        // examinationStore.setTotalTime(timeTest);
      } else if (examinationStore.divisionActive == 2 || examinationStore.divisionActive == 3) {
        examinationStore.setTotalTime(720);
        // examinationStore.setTotalTime(timeTest);
      }
    } else if (examinationStore.examType === 2) {
      if (examinationStore.divisionActive == 1) {
        examinationStore.setTotalTime(360);
        // examinationStore.setTotalTime(timeTest);
      } else if (examinationStore.divisionActive == 2 || examinationStore.divisionActive == 3) {
        examinationStore.setTotalTime(720);
        // examinationStore.setTotalTime(timeTest);
      }
    }
    examinationStore.isStart = true;
  };

  // const stopTime = () => {
  //   examinationStore.isStart = false;
  //   examinationStore.setTotalTime(0);
  // };

  const getQuestionList = async () => {
    const parameters: any = {
      exam_type: examinationStore.examType,
      division: 2,
      page: 1,
      limit: 10
    };
    if (examinationStore.pageActive) {
      parameters.page = examinationStore.pageActive;
      setPageActive(examinationStore.pageActive);
    } else {
      examinationStore.setPageActive(1);
      setPageActive(1);
    }

    if (examinationStore.divisionActive) {
      parameters.division = examinationStore.divisionActive;
      setDivision(examinationStore.divisionActive);
    } else {
      examinationStore.setDivisionActive(2);
      setDivision(2);
    }

    const response = await ExaminationService.getQuestionList(parameters);
    if (response && response.questions) {
      setQuestionList(response.questions);
      setTotalPage(response.totalPage);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    (async () => {
      await getQuestionList();
      setIsLoading(false);
      if (!examinationStore.isStart) {
        startTime();
      }
    })();
  }, []);

  const updateAnswerOfQuestion = async (data) => {
    const response = await ExaminationService.addAnswerDetail(data);
    if (response && response.result?.status === 'success') {
      console.log(response);
      return response;
    } else {
      return false;
    }
  };

  const nextQuestionHandle = async () => {
    if (!validateChooseAnswer()) {
      alert('未回答の選択肢があります');
      return;
    }
    window.scrollTo(0, 0);

    examinationStore.setPageActive(pageActive + 1);
    setPageActive(pageActive + 1);
    await getQuestionList();
    // }
  };

  const checkEndPage = () => {
    if (
      (examinationStore.examType == 1 && division == 3) ||
      (examinationStore.examType == 2 && division == 2)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const nextDivisionHandle = async () => {
    if (!validateChooseAnswer()) {
      alert('未回答の選択肢があります');
      return;
    }
    setIsLoading(true);
    let examinationData = JSON.parse(localStorage.getItem('examinationData') || 'null');
    if (examinationData) {
      let dataAnswer = {
        answer_id: examinationStore.answerId,
        answers: examinationData.answers.filter((answer) => answer.division === division),
        examType: examinationStore.examType,
        urlId: examinationStore.urlId,
        baseId: examinationStore.baseId
      };
      const response = await updateAnswerOfQuestion(dataAnswer);
      setIsLoading(false);
      if (response) {
        if (checkEndPage()) {
          setIsEndPage(true);
          pushDataForThirdParty(response, examinationStore.responseUrl);
          return;
        }
        examinationStore.pageActive = 1;
        setPageActive(1);
        setDivision(3);
        todoStore.setPageName('introduces');
      }
    }
  };

  const handleNextDivisionTimout = async (division) => {
    if (division == 2) {
      await nextDivisionHandle();
    }
  };

  const handleSubmitDataTimeout = async () => {
    let examinationData = JSON.parse(localStorage.getItem('examinationData') || 'null');
    if (examinationData) {
      let dataAnswer = {
        answer_id: examinationStore.answerId,
        answers: examinationData.answers.filter(
          (answer) => answer.division === division && answer.page === pageActive
        )
      };
      let response = await updateAnswerOfQuestion(dataAnswer);
      pushDataForThirdParty(response, examinationStore.responseUrl);
      localStorage.removeItem('examinationData');
    }
  };

  const validateChooseAnswer = () => {
    let examinationData = JSON.parse(localStorage.getItem('examinationData') || 'null');
    if (examinationData) {
      if (questionList.length > 0) {
        let idAnswers: any[] = [];
        questionList.filter((question) => {
          let idAnswerDetails = question.m_question_details.map((detail) => ({
            questionNo: question.question_no,
            detailNo: detail.detail_no,
            division: detail.division
          }));
          idAnswers = [...idAnswerDetails, ...idAnswers];
        });

        if (examinationData.answers?.length > 0) {
          let isValid = idAnswers.every((answer) =>
            examinationData.answers.some(
              (answerChose) =>
                answerChose.questionNo == answer.questionNo &&
                answerChose.detailNo == answer.detailNo &&
                answerChose.division == answer.division
            )
          );
          if (isValid) return true;
        }
        return false;
      } else {
        return false;
      }
    }
  };

  return (
    <>
      {!isEndPage && questionList.length > 0 && (
        <>
          <Header examType={examinationStore.examType} />
          {!examinationStore.isTimeOut && (
            <>
              <TimeProcessContainer />
              <Typography component="div" sx={{ width: '97%', margin: 'auto' }}>
                <Typography component="span">
                  以下の項目について、日頃あなたはどのように考えているかを以下の５項目で当てはまる項目に⦿チェックをいれてください。
                </Typography>
              </Typography>
              <Divider sx={sxStyle.dividerHome} />
              {questionList.map((question, index) => (
                <AnswerOptionForList
                  key={index}
                  currentPage={pageActive}
                  questionNo={question?.question_no}
                  questionDescription={question?.description}
                  division={division}
                  questionDetails={question.m_question_details}
                />
              ))}
              <Typography sx={sxStyle.btnRegion} component="div">
                {pageActive && pageActive !== totalPage && (
                  <Button
                    sx={sxStyle.btnAction}
                    variant="outlined"
                    color="primary"
                    onClick={nextQuestionHandle}
                  >
                    次の問題へ
                  </Button>
                )}
                {pageActive === totalPage && (
                  <Button
                    sx={sxStyle.btnAction}
                    variant="outlined"
                    color="primary"
                    onClick={nextDivisionHandle}
                  >
                    {checkEndPage() ? '送信する' : '第 ' + (division + 1) + ' 部へ進む'}
                  </Button>
                )}
              </Typography>
            </>
          )}
          {examinationStore.isTimeOut && (
            <TimeOut
              handleNextDivisionTimout={handleNextDivisionTimout}
              handleSubmitData={handleSubmitDataTimeout}
            />
          )}
        </>
      )}

      {!isLoading && !isEndPage && questionList.length <= 0 && (
        <>
          <Typography component={'div'}>Not found data</Typography>
        </>
      )}
      {isEndPage && <CompleteExamination />}
      {isLoading && <ModalLoading />}
    </>
  );
});

const sxStyle = {
  dividerHome: { backgroundColor: '#0ca5b0', width: '98%', height: '2px', margin: 'auto' },
  btnRegion: {
    marginBottom: '20px !important',
    bottom: '20px',
    width: '95%',
    margin: 'auto',
    marginTop: '40px',
    height: '150px'
  },
  btnAction: {
    width: '236px',
    height: '70px',
    fontSize: '20px',
    color: '#fff',
    backgroundColor: '#088a98',
    '&:hover': {
      opacity: '.4',
      background: '#088a98',
      borderColor: '#088a98'
    }
  },
  answerRegion: {
    display: 'flex',
    alignItems: 'center'
  },
  answerRegionMobile: {
    display: 'flex'
  }
};

export default MutipleQuestions;
