import { Typography } from '@mui/material';
import * as React from 'react';
// import logo from '../../../logo.svg';

const HeaderOnlyLogo = () => {
  return (
    <Typography component="div" sx={sxStyle.root}>
      {/*<img style={{ justifyContent: 'center' }} src={logo} alt="" />*/}
    </Typography>
  );
};

const sxStyle = {
  root: { margin: 'auto 0', width: '100%', display: 'inherit', marginBottom: '50px' }
};

export default HeaderOnlyLogo;
