import { Typography } from '@mui/material';
import React from 'react';
import AnswerOptions from '../AnswerOptions';
import { useMediaQuery } from 'react-responsive';

const AnswerOptionForList = (props: any) => {
  const { currentPage, questionNo, questionDescription, division, questionDetails } = props;
  const isTabletDevice = useMediaQuery({
    // @ts-ignore
    query: '(max-width: 768px)'
  });
  return (
    <>
      <Typography component="div" sx={sxStyle.root}>
        <Typography component="div" sx={sxStyle.questionRegion}>
          <Typography component="span" sx={sxStyle.questionNumber}>
            {'設問' + questionNo + ':'}
          </Typography>
          <Typography component="span" sx={sxStyle.questionDes}>
            {questionDescription.split('\n').map((content, idx) => (
              <Typography key={idx} component="p">
                {content}
              </Typography>
            ))}
          </Typography>
        </Typography>

        {questionDetails?.map((questionDetail, idx) => (
          <Typography
            key={'div' + idx}
            component={'div'}
            sx={isTabletDevice ? sxStyle.answerRegionMobile : sxStyle.answerRegion}
          >
            {/*<Typography*/}
            {/*  key={'span' + idx}*/}
            {/*  sx={{ marginTop: '20px', mr: 2, fontWeight: 'bold' }}*/}
            {/*  component={'span'}*/}
            {/*>*/}
            {/*  {idx + 1 + ')'}*/}
            {/*</Typography>*/}
            <AnswerOptions
              key={idx}
              questionNo={questionNo}
              detailNo={questionDetail?.detail_no}
              division={division}
              currentPage={currentPage}
              choice1={questionDetail?.choice1}
              choice2={questionDetail?.choice2}
              choice3={questionDetail?.choice3}
              choice4={questionDetail?.choice4}
              choice5={questionDetail?.choice5}
              type={'mutiple'}
            />
          </Typography>
        ))}
      </Typography>
    </>
  );
};

const sxStyle = {
  root: { width: '100%', marginTop: '50px', margin: 'auto', marginBottom: '40px' },
  questionRegion: { display: 'flex', width: '100%' },
  questionNumber: {
    marginRight: '15px',
    width: '10%',
    textAlign: 'left',
    fontSize: '16px',
    fontWeight: 'bold'
  },
  questionDes: {
    display: 'flex',
    width: '80%',
    textAlign: 'left',
    fontSize: '16px',
    fontWeight: 'bold'
  },
  radioRegion: { display: 'flex', margin: 'auto' },
  descriptionAnswer: {},
  answerRegion: {
    display: 'flex',
    alignItems: 'center'
  },
  answerRegionMobile: {
    display: 'flex'
  }
};

export default AnswerOptionForList;
