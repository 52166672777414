import { Typography } from '@mui/material';
import React, { useEffect } from 'react';

const CompleteExamination = () => {
  useEffect(() => {
    let examinationData = JSON.parse(localStorage.getItem('examinationData') || 'null');
    if (examinationData) {
      localStorage.setItem('examinationData', '');
    }
  }, []);

  return (
    <Typography component="div" sx={sxStyle.root}>
      <Typography component={'div'}>ご回答ありがとうございました。</Typography>
    </Typography>
  );
};

const sxStyle = {
  root: {
    height: '100vh',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
};

export default CompleteExamination;
