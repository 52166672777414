import axios from 'axios';

export const pushDataForThirdParty = (data, url: string) => {
  if (data?.result && data.result?.status === 'success' && url) {
    axios
      .post(url, {
        report: [
          {
            exam_type: data.result.exam_type,
            answer_id: data.result.answer_id,
            url_id: data.result.url_id,
            evaluation: data.result.evaluation,
            comment: data.result.comment
          }
        ]
      })
      .then((res) => {
        console.log(res);
        console.log(res.data);
      });
  }
};
