import { Button, Typography } from '@mui/material';
import React from 'react';
import { useStore } from 'stores';

export const TimeOut = (props: any) => {
  const { handleNextDivisionTimout, handleSubmitData } = props;
  const { examinationStore } = useStore();

  const handleNextDivision = (division: number) => {
    handleNextDivisionTimout(division);
  };

  return (
    <>
      <Typography component="div" sx={sxStyle.notiRegion}>
        <Typography component="span" sx={sxStyle.notiDescription}>
          制限時間となりました。
        </Typography>
      </Typography>
      <Typography sx={sxStyle.btnRegion} component="div">
        {((examinationStore.examType == 1 && examinationStore.divisionActive == 3) ||
          (examinationStore.examType == 2 && examinationStore.divisionActive == 2)) && (
          <Button variant="outlined" color="primary" onClick={handleSubmitData}>
            送信する
          </Button>
        )}
        {examinationStore.divisionActive == 1 && (
          <Button
            sx={sxStyle.btnAction}
            variant="outlined"
            color="primary"
            onClick={() => handleNextDivision(1)}
          >
            第２部へ進む
          </Button>
        )}
        {examinationStore.examType == 1 && examinationStore.divisionActive == 2 && (
          <Button variant="outlined" color="primary" onClick={() => handleNextDivision(2)}>
            第３部に進む
          </Button>
        )}
      </Typography>
    </>
  );
};

const sxStyle = {
  notiRegion: {
    width: '100%',
    marginTop: '50px'
  },
  notiDescription: {
    fontSize: '40px'
  },
  btnRegion: {
    marginBottom: '20px !important',
    bottom: '20px',
    width: '95%',
    margin: 'auto',
    marginTop: '40px',
    height: '150px'
  },
  btnAction: {
    width: '236px',
    height: '70px',
    fontSize: '20px',
    color: '#fff',
    backgroundColor: '#088a98',
    '&:hover': {
      opacity: '.4',
      background: '#088a98',
      borderColor: '#088a98'
    }
  }
};
