import axiosClient from './axiosClient';

const ExaminationService = {
  async getDataFromUrlKey(params: any): Promise<any> {
    const url = '/api/examination/getDataFromUrlKey';
    let response: any;
    response = await axiosClient.get(url, { params: params });
    return response;
  },
  async getQuestionList(params: any): Promise<any> {
    const url = '/api/examination/getQuestionList';
    let response: any;
    response = await axiosClient.get(url, { params: params });
    return response;
  },
  async addInfoExamination(data: any): Promise<any> {
    const url = '/api/examination/addAnswer';
    let response: any;
    response = await axiosClient.post(url, data);
    return response;
  },
  async addAnswerDetail(data: any): Promise<any> {
    const url = '/api/examination/addAnswerDetail';
    let response: any;
    response = await axiosClient.post(url, data);
    return response;
  },
  async addInfoExaminationAndAnswerDivision1(data: any): Promise<any> {
    const url = '/api/examination/addInfoExaminationAndAnswerDivision1';
    let response: any;
    response = await axiosClient.post(url, data);
    return response;
  }
};

export default ExaminationService;
