import { Button, Typography } from '@mui/material';
import ModalLoading from 'components/Loading/ModalLoading';
import CandidateInfomation from 'features/candidate-information';
import { Form, Formik, FormikHelpers, FormikValues } from 'formik';
import { useState } from 'react';
import ExaminationService from 'services/examinationService';
import { useStore } from 'stores';
import { formModel, initialValues, validationSchema } from 'utils';

const { formField } = formModel;
interface ExaminationPageProps {
  // eslint-disable-next-line no-unused-vars
  handleErrorMessage?: (message: string) => void;
}

export const ExaminationPage = (props: ExaminationPageProps) => {
  const { handleErrorMessage = () => {} } = props;
  const selectedValidationSchema = validationSchema[0];
  const { todoStore, examinationStore } = useStore();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const submitForm = async (values: FormikValues, actions: FormikHelpers<FormikValues>) => {
    console.log(values);
    console.log(actions);
  };

  const addInfoExamination = async (data: any) => {
    const response = await ExaminationService.addInfoExamination(data);
    if (response && response.result?.status === 'success') {
      console.log(response);
      examinationStore.setAnswerId(response?.result?.answer_id);
    } else if (response && response.result?.status === 'error') {
      todoStore.setPageName('error');
      handleErrorMessage(response.result?.message);
    }
    return response.result?.status;
  };

  const handleSubmit = async (values: FormikValues, actions: FormikHelpers<FormikValues>) => {
    if (todoStore.pageName === 'examination') {
      if (values) {
        let examinationData: any = {};
        examinationData.values = values;
        if (examinationStore.issueType == 2) {
          setIsLoading(true);
          let dataAnswerUpdate = {
            base_id: examinationStore.baseId,
            url_id: examinationStore.urlId,
            exam_type: examinationStore.examType,
            name: values.firstName + values.lastName,
            name_kana: values.furigana1 + values.furigana2,
            age: values.age,
            department: values.department
          };
          let response = await addInfoExamination(dataAnswerUpdate);
          setIsLoading(false);
          if (response === 'error') return;
        }
        localStorage.setItem('examinationData', JSON.stringify(examinationData));
        todoStore.setPageName('introduces');
      }
      return;
    }
    if (values) {
      submitForm(values, actions);
    } else {
      let isError = false;
      if (!isError) actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  return (
    <>
      <Typography component="div" sx={sxStyle.root}>
        <Formik
          initialValues={initialValues}
          validationSchema={selectedValidationSchema}
          onSubmit={handleSubmit}
        >
          {(formikProps) => (
            <Form>
              <CandidateInfomation
                formField={formField}
                errors={formikProps.errors}
                touched={formikProps.touched}
              />
              <Typography component="div" sx={sxStyle.buttonRegion}>
                <Button sx={sxStyle.btnAction} color="primary" variant="outlined" type="submit">
                  第１部に進む
                </Button>
              </Typography>
            </Form>
          )}
        </Formik>
      </Typography>
      {isLoading && <ModalLoading />}
    </>
  );
};

const sxStyle = {
  root: {},
  buttonRegion: { marginTop: '50px', display: 'flex' },
  btnAction: {
    width: '236px',
    height: '70px',
    fontSize: '20px',
    color: '#fff',
    margin: 'auto',
    backgroundColor: '#088a98',
    '&:hover': {
      opacity: '.4',
      background: '#088a98',
      borderColor: '#088a98'
    }
  }
};

export default ExaminationPage;
