import { Typography } from '@mui/material';
import { ErrorPage, NotFound } from 'components/Common';
import HeaderOnlyLogo from 'components/Layout/Header/HeaderOnlyLogo';
import DatFullScreenPage from 'features/datf';
import DatMScreenPage from 'features/datm';
import ExaminationPage from 'features/examanition';
import QuestionList from 'features/questions/SingleQuestion';
import MutipleQuestions from 'features/questions/MutipleQuestions';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import ExaminationService from 'services/examinationService';
import { useStore } from 'stores';
import Introduce from '../introduce';
import ModalLoading from '../../components/Loading/ModalLoading';

export const HomePage = observer(() => {
  const { todoStore, examinationStore } = useStore();
  const params = useParams();
  const [searchParams] = useSearchParams();

  const [dataInfo, setDataInfo] = useState<any>({});
  const [errorMsg, setErrorMsg] = useState<string>('');

  useEffect(() => {
    let examinationData = JSON.parse(localStorage.getItem('examinationData') || 'null');
    if (examinationData && examinationData.answers?.length > 0) {
      examinationData.answers = [];
      localStorage.setItem('examinationData', JSON.stringify(examinationData));
    }
  }, []);

  useEffect(() => {
    todoStore.setPageName('loading');
    (async () => {
      const parameters = {
        urlKey: params['*'],
        exam_type: searchParams.get('exam_type'),
        key: searchParams.get('key'),
        response_url: searchParams.get('response_url')
      };
      const response = await ExaminationService.getDataFromUrlKey(parameters);
      if (response && response.dataUrl && response.status != 'error') {
        setDataInfo({ bmname: response.bmname, basename: response.basename });
        let type = response.dataUrl.exam_type === 1 ? 'datf' : 'datm';
        if (
          response.division == 0 ||
          response.dataUrl.issue_type == 1 ||
          (response.dataUrl.exam_type == 1 && response.division == 3) ||
          (response.dataUrl.exam_type == 2 && response.division == 2)
        ) {
          todoStore.setPageName(type);
        } else {
          todoStore.setPageName('introduces');
        }
        examinationStore.setDivisionActive(
          response.dataUrl.issue_type == 2 ? response.division : 0
        );
        examinationStore.setBaseId(response.dataUrl.base_id);
        examinationStore.setUrlId(response.dataUrl.id);
        examinationStore.setExamType(response.dataUrl.exam_type);
        examinationStore.setAnswerId(response.answer_id);
        examinationStore.setIssueType(response.dataUrl.issue_type);
        examinationStore.setResponseUrl(response.response_url);

        if (response.dataUrl.exam_type == 1) {
          if (response.division == 1) {
            examinationStore.setTotalTime(1500);
            // examinationStore.setTotalTime(timeTest);
          } else if (response.division == 2 || response.division == 3) {
            examinationStore.setTotalTime(720);
            // examinationStore.setTotalTime(timeTest);
          }
        } else if (response.dataUrl.exam_type === 2) {
          if (response.division == 1) {
            examinationStore.setTotalTime(360);
            // examinationStore.setTotalTime(timeTest);
          } else if (response.division == 2) {
            examinationStore.setTotalTime(720);
            // examinationStore.setTotalTime(timeTest);
          }
        }
      } else if (response.status == 'error') {
        todoStore.setPageName('error');
        setErrorMsg(response.err_msg);
      } else {
        todoStore.setPageName('notFound');
      }
    })();
  }, []);

  const checkDivision = () => {
    if (examinationStore?.divisionActive) {
      if (examinationStore.divisionActive === 1) {
        return <QuestionList />;
      } else {
        return <MutipleQuestions divisionProp={examinationStore?.divisionActive} />;
      }
    }
  };

  return (
    <>
      <Typography component="div" sx={sxStyle.root}>
        {todoStore.pageName !== 'questions' && todoStore.pageName !== 'notFound' && (
          <HeaderOnlyLogo />
        )}
        {todoStore.pageName === 'datm' && (
          <DatMScreenPage bmname={dataInfo.bmname} basename={dataInfo.basename} />
        )}
        {todoStore.pageName === 'datf' && (
          <DatFullScreenPage bmname={dataInfo.bmname} basename={dataInfo.basename} />
        )}
        {todoStore.pageName === 'examination' && (
          <ExaminationPage handleErrorMessage={(message: string) => setErrorMsg(message)} />
        )}

        {todoStore.pageName === 'introduces' && <Introduce />}

        {todoStore.pageName === 'questions' && checkDivision()}
        {todoStore.pageName === 'notFound' && <NotFound />}
        {todoStore.pageName === 'loading' && <ModalLoading />}
        {todoStore.pageName === 'error' && <ErrorPage message={errorMsg} />}
      </Typography>
    </>
  );
});

const sxStyle = {
  root: { width: '100%', margin: 'auto', maxWidth: '960px' }
};

export default HomePage;
